import React, { useState, useEffect, forwardRef } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {CardService} from '../atom';
import { db, colors } from '../../utils';
import { ref, onValue } from "firebase/database";

const Services = forwardRef(function ({ button }, serviceRef) {
    const [loading, setLoading] = useState(true);
    const Ref = ref(db, 'services');
    const [list, setList] = useState({});

    const onDataChange = (snapshot) => {
        const data = snapshot.val();
        setList(data);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    useEffect(() => {
        return onValue(Ref, onDataChange, {
            /** realtime disable */
            onlyOnce: true
        });
    }, [Ref]);
    
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const targets = entry.target.querySelectorAll('.card-service');
                targets.forEach(target => {
                    if (entry.isIntersecting) {
                        if (target !== null) {
                            target.classList.add('animate__fadeInUp');
                            return;
                        }
                    }
                });
            });
        });

        observer.observe(document.querySelector('#content-services'));
    }, []);
    
    return (
        <SkeletonTheme baseColor={colors.baseColorSkeleton} highlightColor={colors.highlightColorSkeleton}>
            <section ref={serviceRef} id="services">
                <div className="container" id="content-services">
                    <h2 className="title text-center">My Services</h2>
                    <p className="subtitle text-center">I can provide some of the services you need, such as: </p>
                    <div className="row" style={{ marginTop: 50 }}>
                        {loading ? (
                            <React.Fragment>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <Skeleton height={150} width={'100%'} />
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <Skeleton height={150} width={'100%'} />
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <Skeleton height={150} width={'100%'} />
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <Skeleton height={150} width={'100%'} />
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <Skeleton height={150} width={'100%'} />
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <Skeleton height={150} width={'100%'} />
                                </div>
                            </React.Fragment>
                        ) : (
                            Object.entries(list).length > 0 ?
                                list.map((element, i) => {
                                    return (
                                        <div key={i} style={{animationDelay:`${(i/2 - 0.6)}s`}} className="col-lg-4 col-md-6 mb-4 card-service animate__animated">
                                            <CardService param={element} ></CardService>
                                        </div>
                                    );
                                })
                                : null
                        )}
                    </div>
                </div>
            </section>
        </SkeletonTheme>

    );
})

export default Services;
