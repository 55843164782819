import React, { useState, useEffect } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { db, colors } from '../../utils';
import { ref, onValue } from "firebase/database";

function Sosmed() {
    const [loading, setLoading] = useState(true);
    const Ref = ref(db, 'contact');
    const [list, setList] = useState({});

    const onDataChange = (snapshot) => {
        const data = snapshot.val();
        setList(data);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    useEffect(() => {
        return onValue(Ref, onDataChange, {
            /** realtime disable */
            onlyOnce: true
        });
    }, [Ref]);

    return (
        <SkeletonTheme baseColor={colors.baseColorSkeleton} highlightColor={colors.highlightColorSkeleton}>
            <ul className="sosmed">
                {loading ? (
                    <React.Fragment>
                        <Skeleton circle={true} inline={true} style={{marginRight:10}} height={50} width={50} />
                        <Skeleton circle={true} inline={true} style={{ marginRight: 10 }} height={50} width={50} />
                        <Skeleton circle={true} inline={true} style={{ marginRight: 10 }} height={50} width={50} />
                        <Skeleton circle={true} inline={true} style={{marginRight:10}} height={50} width={50} />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <li><a href={"https://www.facebook.com/"+list.facebook}
                            className="btn btn-round btn-outline-warning"><i className="fab  fa-facebook-f"></i></a></li>
                        <li><a href={"https://www.instagram.com/"+list.instagram}
                            className="btn btn-round btn-outline-warning"><i className="fab  fa-instagram"></i></a></li>
                        <li><a href={"https://www.linkedin.com/in/"+list.linkedin}
                            className="btn btn-round btn-outline-warning"><i className="fab  fa-linkedin"></i></a></li>
                        <li><a href={"https://github.com/"+list.github} className="btn btn-round btn-outline-warning"><i
                            className="fab  fa-github"></i></a></li>
                        <li></li>
                    </React.Fragment>
                )}
            </ul>
        </SkeletonTheme>
    );
}

export default Sosmed;
