import React, { useRef, useEffect } from 'react';
import { 
    Banner, 
    Services, 
    About, 
    Contact, 
    Skills 
} from '../components'; 
import {
    useLocation
} from "react-router-dom";

function Landing() {
    const homeRef = useRef();
    const serviceRef = useRef();
    const aboutRef = useRef();
    const skillsRef = useRef();
    const contactRef = useRef();

    let match = useLocation();
    // console.log(match);
    useEffect(() => {
        switch (match.pathname) {
            case "/contact":
                contactRef.current.scrollIntoView({ behavior: 'smooth' })
                break;
            case "/services":
                serviceRef.current.scrollIntoView({ behavior: 'smooth' })
                break;
            case "/about":
                aboutRef.current.scrollIntoView({ behavior: 'smooth' })
                break;
            case "/skills":
                skillsRef.current.scrollIntoView({ behavior: 'smooth' })
                break;
            case "/home":
                homeRef.current.scrollIntoView({ behavior: 'smooth' })
                break;
            default :
                homeRef.current.scrollIntoView({ behavior: 'smooth' })
                break;

        }
    }, [match]);


    return (
        <React.Fragment>
            <Banner ref={homeRef}></Banner>
            <Services ref={serviceRef}></Services>
            <About ref={aboutRef}></About>
            <Skills ref={skillsRef}></Skills>
            <Contact ref={contactRef} ></Contact>
        </React.Fragment>
    );
}

export default Landing;
