import React, { forwardRef, useEffect } from 'react';
import Sosmed from './Sosmed';

const Contact = forwardRef(({button}, contactRef)  => {
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const target = entry.target.querySelector('#contact-content');
                if (entry.isIntersecting) {
                    if (target !== null) {
                        target.classList.add('animate__slideInUp');
                        return;
                    }
                }
            });
        });

        observer.observe(document.querySelector('#contact'));
    }, []);

    return (
        <React.Fragment>
            <section ref={contactRef} id="contact" className="bg-dark">
                <div className="container animate__animated" id="contact-content">
                    <h2 className="title text-center">Contact Me</h2>
                    <p className="subtitle text-center">Have any challenge for me? You can find me on...</p>
                    <div className="row">
                        <div className="col-12 text-center">
                            <Sosmed></Sosmed>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>

    );
})

export default Contact;
