
import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyA4w4d_8_VSToz5QoQ_6VFsCbr-bp8OMRQ",
    authDomain: "personal-app-afb92.firebaseapp.com",
    projectId: "personal-app-afb92",
    storageBucket: "personal-app-afb92.appspot.com",
    messagingSenderId: "1096571521686",
    appId: "1:1096571521686:web:c576ca777c5dc66edcd0b7",
    measurementId: "G-SHWYS1GSDY",
    databaseURL: "https://personal-app-afb92-default-rtdb.asia-southeast1.firebasedatabase.app"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export default database;