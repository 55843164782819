import React from 'react';

function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <span className="text-muted">Copyright © 2021 saepul<span className="font-primary">.</span>asep | All Rights Reserved </span>
            </div>
        </footer>
    );
}

export default Footer;
