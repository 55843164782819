import React, { forwardRef, useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { db, colors } from '../../utils';
import { ref, onValue } from "firebase/database";

const About = forwardRef(({button}, aboutRef) => {
    const [loading, setLoading] = useState(true);
    const Ref = ref(db, 'about_me');
    const [list, setList] = useState({});

    const onDataChange = (snapshot) => {
        const data = snapshot.val();
        setList(data);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    useEffect(() => {
        return onValue(Ref, onDataChange, {
            /** realtime disable */
            onlyOnce: true
        });
    }, [Ref]);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const targets = entry.target.querySelectorAll('.animate-about');
                targets.forEach(target => {
                    if (entry.isIntersecting) {
                        if (target !== null) {
                            target.classList.add('animate__fadeInUp');
                            return;
                        }
                    }
                });
            });
        });
        observer.observe(document.querySelector('#about'));
    }, []);

    return (
        <SkeletonTheme baseColor={colors.baseColorSkeleton} highlightColor={colors.highlightColorSkeleton}>
            <section ref={aboutRef} id="about">
                <div className="container">
                    <h2 id="about-title" className="title text-center animate__animated">About Me</h2>
                    <p className="subtitle text-center">More informations about me</p>
                    {loading ? (
                        <div className="row" id="content-about">
                            <div className="col-lg-5 about-img-skeleton">
                                <Skeleton circle={true} height={200} width={200} style={{ margin: 'auto', display: 'block' }} />
                            </div>
                            <div className="col-lg-7 col-md-12" style={{ marginTop: -80 }}>
                                <Skeleton height={40} width={'20%'} style={{marginTop:20,marginBottom:20}}/>
                                <Skeleton height={20} count={8} width={'100%'} />
                                <Skeleton height={20} width={'20%'} style={{ marginTop: 10 }}/>
                                <Skeleton height={20} width={'40%'} />
                                <Skeleton height={20} width={'20%'} style={{ marginTop: 10 }} />
                                <Skeleton height={20} width={'40%'} />
                            </div>
                        </div>
                    ) : (
                        <div className="row" id="content-about">
                            <div className="col-lg-5 animate-about animate__animated">
                                <img src={list.image_url} alt="About" className="about-img" loading="lazy" />
                            </div>
                            <div className="col-lg-7 animate-about col-md-12 animate__animated" style={{ marginTop: -60 }}>
                                <p className="text-header">Hi There</p>
                                <p className="text" dangerouslySetInnerHTML={{ __html: list.desc }} ></p>
                                <div className="row text">
                                    <div className="col-sm-6"><b className="font-primary">Name</b>: <br />{list.fullname}</div>
                                    <div className="col-sm-6"><b className="font-primary">Email</b>: <br />{list.email}</div>
                                </div>
                            </div>
                        </div>
                    )}
                    
                </div>
            </section>
        </SkeletonTheme>
    );
})

export default About;
