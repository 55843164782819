import React from 'react';
import { Link } from "react-router-dom";

function NotFound() {
    return (
        <React.Fragment>
            <section id="notfound">
                <div className="container text-center text-white">
                    <h3> Are you lost?</h3>
                    <p>Some page are still in development, maybe you can go back if you want</p>
                    <Link className="btn btn-outline-warning mt-3" to={"/home"}>Yes, bring me to safe place please</Link>
                </div>
            </section>
        </React.Fragment>
    );
}

export default NotFound;
