import React, { useState, useEffect } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { db, colors } from '../utils';
import { ref, onValue } from "firebase/database";
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';

function Experience() {

    const [loading, setLoading] = useState(true);
    const Ref = ref(db, 'experience');
    const [list, setList] = useState({});

    const onDataChange = (snapshot) => {
        const data = snapshot.val();
        setList(data);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    useEffect(() => {
        return onValue(Ref, onDataChange, {
            /** realtime disable */
            onlyOnce: true
        });
    }, [Ref]);

    return (
        <SkeletonTheme baseColor={colors.baseColorSkeleton} highlightColor={colors.highlightColorSkeleton}>
            <section id="experience">
                <div className="container text-center">
                    <h2 className="experience-title animate__animated animate__fadeInUp" >My Experience</h2>
                    <p className="experience-subtitle animate__animated animate__fadeInUp">My previous jobs and my qualifications.</p>

                    <div id="experience-content" style={{marginTop: 50}}>
                        {loading ? (
                            <VerticalTimeline animate={false}>
                                <VerticalTimelineElement
                                    contentStyle={{ background: '#000', boxShadow: '0 3px 0 #000', color: '#fff', borderColor: '#000', textAlign: 'left'  }}
                                    date=""
                                    dateClassName="color-white"
                                    iconStyle={{ background: colors.highlightColorSkeleton, color: '#000' }}
                                    contentArrowStyle={{ borderRight: '7px solid  #000' }}
                                >
                                    <Skeleton height={25} width={'80%'} />
                                    <Skeleton height={40} width={'100%'} style={{marginTop:20, marginBottom:20}} />
                                    
                                    <Skeleton inline={true} style={{ marginRight: 10 }} height={15} width={50} />
                                    <Skeleton inline={true} style={{ marginRight: 10 }} height={15} width={50} />
                                    <Skeleton inline={true} style={{ marginRight: 10 }} height={15} width={50} />

                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    contentStyle={{ background: '#000', boxShadow: '0 3px 0 #000', color: '#fff', borderColor: '#000', textAlign: 'left' }}
                                    date=""
                                    dateClassName="color-white"
                                    iconStyle={{ background: colors.highlightColorSkeleton, color: '#000' }}
                                    contentArrowStyle={{ borderRight: '7px solid  #000' }}
                                >
                                    <Skeleton height={25} width={'80%'} />
                                    <Skeleton height={40} width={'100%'} style={{ marginTop: 20, marginBottom: 20 }} />

                                    <Skeleton inline={true} style={{ marginRight: 10 }} height={15} width={50} />
                                    <Skeleton inline={true} style={{ marginRight: 10 }} height={15} width={50} />
                                    <Skeleton inline={true} style={{ marginRight: 10 }} height={15} width={50} />

                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    contentStyle={{ background: '#000', boxShadow: '0 3px 0 #000', color: '#fff', borderColor: '#000', textAlign: 'left' }}
                                    date=""
                                    dateClassName="color-white"
                                    iconStyle={{ background: colors.highlightColorSkeleton, color: '#000' }}
                                    contentArrowStyle={{ borderRight: '7px solid  #000' }}
                                >
                                    <Skeleton height={25} width={'80%'} />
                                    <Skeleton height={40} width={'100%'} style={{ marginTop: 20, marginBottom: 20 }} />

                                    <Skeleton inline={true} style={{ marginRight: 10 }} height={15} width={50} />
                                    <Skeleton inline={true} style={{ marginRight: 10 }} height={15} width={50} />
                                    <Skeleton inline={true} style={{ marginRight: 10 }} height={15} width={50} />

                                </VerticalTimelineElement>
                            </VerticalTimeline>
                        ) : (
                            <VerticalTimeline>
                                {
                                    Object.entries(list).length > 0 ?
                                        list.map((e, i) => {
                                            return (
                                                <VerticalTimelineElement
                                                    contentStyle={{ background: '#fff', borderTop: '5px solid ' + colors.secondary, textAlign: 'left' }}
                                                    date={e.date}
                                                    dateClassName="color-white"
                                                    iconStyle={{ background: colors.secondary, color: '#fff' }}
                                                    icon={e.icon === 'work' ? <WorkIcon /> : e.icon === 'school' ? <SchoolIcon /> : null}
                                                >
                                                    <h3 className="experience-card-title" style={{ fontSize: 20 }} dangerouslySetInnerHTML={{ __html: e.title }} ></h3>
                                                    <p className="experience-card-desc" dangerouslySetInnerHTML={{ __html: e.desc }} >
                                                    </p>
                                                    <div className="experience-card-tools mt-3">
                                                        <h4 style={{ fontSize: 15, fontWeight: 'bold' }}>Tools : </h4>
                                                        {
                                                            Object.entries(e.tools).length > 0 ? 
                                                                e.tools.map((tool, i) => {
                                                                    return (
                                                                        <React.Fragment>
                                                                            <span className={`badge badge-${tool.type} mr-1 mb-1`}>{tool.name}</span>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            : null                                                            
                                                        }
                                                    </div>
                                                </VerticalTimelineElement>
                                            )
                                        })
                                    : null
                                }
                            </VerticalTimeline>
                        )}
                        
                    </div> 
                </div>
            </section>
        </SkeletonTheme>
    );
}

export default Experience;
