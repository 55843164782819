import './assets/styles';
import 'animate.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-vertical-timeline-component/style.min.css';
import { ScrollToTop } from './utils'; 
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import {
  Navbar,
  Footer,
} from './components'; 
import {
  Landing, 
  Experience,
  Portfolio,
  NotFound
} from './pages';
import { createBrowserHistory } from "history";

function App() {
  const history = createBrowserHistory();

  return (
    <Router history={history}>
      <ScrollToTop />
      <React.Fragment>
        <header>
          <Navbar></Navbar>
        </header>
        <main>
            <Routes>
              <Route exact path='*' element={<NotFound />} />
              <Route exact path="/" element={<Landing />} />
              <Route exact path="/home" element={<Landing />} />
              <Route exact path="/services" element={<Landing />} />
              <Route exact path="/about" element={<Landing />} />
              <Route exact path="/contact" element={<Landing />} />
              <Route exact path="/skills" element={<Landing />} />
              <Route exact path="/experience" element={<Experience />} />
              <Route exact path="/portfolio" element={<Portfolio />} />
            </Routes>
        </main>
        <Footer></Footer>
      </React.Fragment>
    </Router>
  );
}

export default App;
