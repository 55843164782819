import React from 'react';

const ProgressBar = ({param}) => {
    return (
        <React.Fragment>
            <label className="subtitle" style={{ marginTop: 10 }}>{param.title} <small className='text-dark badge badge-warning'>{param.experience}</small> </label>
            <div className="progress">
                <div className="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                    role="progressbar" style={{ width: param.percentage }} aria-valuenow="10" aria-valuemin="0"
                    aria-valuemax="100"></div>
            </div>
        </React.Fragment>

    );
}

export default ProgressBar;