import React from 'react';

const Card = ({ param }) => {

    return (
        <div className="card bg-dark p-0">
            <img className="card-img-top" src={param.image_url} alt="Card" style={{height:300, backgroundColor: '#fff'}} />
            <div className="card-body">
                <h5 className="card-title text-warning" dangerouslySetInnerHTML={{ __html: param.title }}></h5>
                <p style={{ fontSize: 16 }} className="card-text text-white" dangerouslySetInnerHTML={{ __html: param.desc }}></p>

                <h4 style={{ fontSize: 16, fontWeight: 'bold', color: '#fff' }}>Built with : </h4>
                {
                    Object.entries(param.tags).length > 0 ?
                        param.tags.map((tag, i) => {
                            return (
                                <React.Fragment>
                                    <span className={`badge badge-${tag.type} mr-1 mb-1`}>{tag.name}</span>
                                </React.Fragment>
                            )
                        })
                        : null
                }
            </div>
            <div className="card-footer mb-2">
                {param.sc_link !== 0 ? (
                    <a href={param.sc_link} className="btn btn-outline-warning mr-2 mb-2">Source Code</a>
                ) : null }
                {param.demo_link !== 0 ? (
                    <a href={param.demo_link} className="btn btn-outline-warning mb-2">Visit App</a>
                ) : null}
            </div>
        </div>

    );
}

export default Card;