import React, { useEffect, useState, forwardRef} from 'react';
import { ProgressBar } from '../atom';
import { db, colors } from '../../utils';
import { ref, onValue } from "firebase/database";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const Skills = forwardRef(({button}, skillsRef) => {
    const Ref = ref(db, 'skills');
    const [loading, setLoading] = useState(true);

    const [list, setList] = useState({});
    const onDataChange = (snapshot) => {
        const data = snapshot.val();
        setList(data);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    useEffect(() => {
        return onValue(Ref, onDataChange, {
            /** realtime disable */
            onlyOnce: true
        });
    }, [Ref]);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const targets = entry.target.querySelectorAll('.progress-card');
                targets.forEach(target => {
                    if (entry.isIntersecting) {
                        if (target !== null) {
                            target.classList.add('animate__fadeInRight');
                            return;
                        }
                    }
                });
            });
        });

        observer.observe(document.querySelector('#skills'));
    }, []);

    return (
        <SkeletonTheme baseColor={colors.baseColorSkeleton} highlightColor={colors.highlightColorSkeleton}>
            <section ref={skillsRef} id="skills">
                <div className="container">
                    <h2 className="title text-left">Skills</h2>
                    <p className="subtitle text-left">I can say i’m quite good at </p>
                        {loading ? (
                            <div>
                                <Skeleton height={25} width={'25%'} />
                                <Skeleton height={25} width={'80%'} style={{marginBottom:20}}/>
                                <Skeleton height={25} width={'25%'} />
                                <Skeleton height={25} width={'85%'} style={{ marginBottom: 20 }} />
                                <Skeleton height={25} width={'25%'} />
                                <Skeleton height={25} width={'90%'} style={{ marginBottom: 20 }} />
                                <Skeleton height={25} width={'25%'} />
                                <Skeleton height={25} width={'95%'} style={{ marginBottom: 20 }} />
                            </div>
                        ) : (
                            <div className = "row">
                                <div className = "col-12">
                                        {
                                            Object.entries(list).length > 0 ?
                                                list.map((element, i) => {
                                                    return (
                                                        <div style={{ animationDelay: `${(i / 2 - 0.6)}s` }} key={i} className="progress-card animate__animated">
                                                            <ProgressBar param={element} ></ProgressBar>
                                                        </div>
                                                    )  
                                                })
                                            : null
                                        }
                                </div>
                            </div>
                        )}
                        
                </div>
            </section>
        </SkeletonTheme>

    );
})

export default Skills;