import React from 'react';

const CardService = ({ param }) => {
    
    return (
        <div className="card bg-dark ">
            <div className="card-body">
                <h5 className="card-title font-primary"><i className={param.icon + " fa-3x"}></i></h5>
                <h5 className="card-title text-white">{param.title}</h5>
            </div>
        </div>

    );
}

export default CardService;