import React, { useState, useEffect } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { db, colors } from '../utils';
import { Card } from '../components/atom';
import { ref, onValue } from "firebase/database";

function Portfolio() {
    const [loading, setLoading] = useState(true);
    const Ref = ref(db, 'portfolio');
    const [list, setList] = useState({});

    const onDataChange = (snapshot) => {
        const data = snapshot.val();
        setList(data);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    useEffect(() => {
        return onValue(Ref, onDataChange, {
            /** realtime disable */
            onlyOnce: true
        });
    }, [Ref]);

    return (
        <SkeletonTheme baseColor={colors.baseColorSkeleton} highlightColor={colors.highlightColorSkeleton}>
            <section id="portfolio">
                <div className="container text-center">
                    <h2 className="experience-title animate__animated animate__fadeInUp" >Portfolio</h2>
                    <p className="experience-subtitle animate__animated animate__fadeInUp">Showcase of my latest works, projects and developments.</p>

                    <div className="card-deck row text-left" style={{marginTop:50}}>
                        {loading ? (
                            <React.Fragment>
                                <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                                    <Skeleton height={150} width={'100%'} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                                    <Skeleton height={150} width={'100%'} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                                    <Skeleton height={150} width={'100%'} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                                    <Skeleton height={150} width={'100%'} />
                                </div>
                            </React.Fragment>
                        ) : (
                            Object.entries(list).length > 0 ?
                                list.map((element, i) => {
                                    return (
                                        <div key={i} style={{ animationDelay: `${(i / 2 - 0.6)}s` }} className="col-lg-6 col-md-6 col-sm-12 mb-4 card-portfolio animate__animated animate__fadeInUp">
                                            <Card param={element} ></Card>
                                        </div>
                                    );
                                })
                                : null
                        )}
                    </div>
                </div>
            </section>
        </SkeletonTheme>
    );
}

export default Portfolio;
