import React, { useState, useEffect, forwardRef } from 'react';
import Skeleton, { SkeletonTheme }  from 'react-loading-skeleton';
import Sosmed from './Sosmed'; 
import { db, colors } from '../../utils';
import { ref, onValue } from "firebase/database";

const Banner = forwardRef(({button}, homeRef)  => {
    const bannerRef = ref(db, 'banner');
    const [loading, setLoading] = useState(true);
    const [Param, setParam] = useState({
                                    header: "",
                                    desc: "",
                                    is_show_socmed: 0,
                                    is_show_resume: 0,
                                    image_url: '',
                                    resume_url: '',
                                });
    const onDataChange = (snapshot) => {
        const data = snapshot.val();
        setParam(data);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    useEffect(() => {
        return onValue(bannerRef, onDataChange, {
            /** realtime disable */
            onlyOnce: true
        });
    }, [bannerRef]);

    return (
        <SkeletonTheme baseColor={colors.baseColorSkeleton} highlightColor={colors.highlightColorSkeleton}>
            <section ref={homeRef} id="home">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-7 order-lg-first order-last">
                            {loading ? (
                                <div className="animate__animated animate__fadeIn">
                                    <Skeleton height={30} width={'50%'} style={{marginBottom:20}} />
                                    <Skeleton count={5} height={25} width={'95%'} />
                                    <Skeleton height={30} style={{ marginTop:20, marginBottom:20 }} width={'30%'} />
                                </div>
                            ) : (
                                <div className="animate__animated animate__fadeIn">
                                    <h2 className = "banner-title" dangerouslySetInnerHTML = {{ __html: Param.header }} ></h2>
                                    <p className="banner-subtitle" dangerouslySetInnerHTML={{ __html: Param.desc }}></p>
                                    {Param.is_show_resume === 1 ? (
                                        <div style={{ marginBottom: '20px' }}>
                                            <a href={Param.resume_url} className="btn btn-outline-warning mt-2">Download My Resume </a>
                                        </div>
                                    ) : null}
                                </div> 
                            )}

                            {Param.is_show_socmed === 1 ? (
                                <Sosmed></Sosmed>
                            ) : null}

                        </div> 
                        <div className="col-lg-5 order-lg-last order-first">
                            {loading ? (
                                <div className="banner-img-skeleton" >     
                                    <Skeleton circle={true} height={200} width={200} style={{margin:'auto', display: 'block'}} />
                                </div>
                            ) : (
                                    <img src={Param.image_url} alt="Banner" className="banner-img animate__animated animate__fadeIn" />
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </SkeletonTheme>

    );
})

export default Banner;
