import React from 'react';
import { shiear } from '../../assets'; 
import { Link, useLocation } from "react-router-dom";

function Navbar() {
    let match = useLocation();

    return (
        <React.Fragment>
            <nav className="navbar fixed-top  navbar-expand-lg navbar-dark bg-dark box-shadow">
                <div className="container">
                    <Link className="navbar-brand" to={"/"}>
                        <img src={shiear} style={{width:150}} className="align-top" alt="icon logo" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse " id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link className={`nav-link ${match.pathname === '/home' ? 'active' : ''}`} to={"/home"}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${match.pathname === '/services' ? 'active' : ''}`} to={"/services"}>Services</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${match.pathname === '/about' ? 'active' : ''}`} to={"/about"}>About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${match.pathname === '/skills' ? 'active' : ''}`} to={"/skills"}>Skills</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${match.pathname === '/contact' ? 'active' : ''}`} to={"/contact"}>Contact</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${match.pathname === '/experience' ? 'active' : ''}`} to="/experience">Experience</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${match.pathname === '/portfolio' ? 'active' : ''}`} to={"/portfolio"}>Portfolio</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </React.Fragment>

    );
}

export default Navbar;
